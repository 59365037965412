<template>
  <div id="mian">
    <!-- 上面表单框 -->
    <div class="con_from">
      <div class="lineP">
        <div class="item">
          <i>报备商户号</i>
          <el-input
            v-model="query.tradeMchNo"
            maxlength="128"
            suffix-icon="el-icon-search"
            placeholder="报备商户号"
          ></el-input>
        </div>
        <div class="item">
          <i class="jianju">第三方商户号</i>
          <el-input
            v-model="query.productChannelMchNo"
            maxlength="128"
            suffix-icon="el-icon-search"
            placeholder="第三方商户号"
          ></el-input>
        </div>
        <div class="itembtn">
          <el-button type="primary" class="searchBt" @click="getLists"
            >查询</el-button
          >
        </div>
      </div>
    </div>
    <div class="tab1">
      <table class="tab">
        <tr class="tab_title">
          <td>
            <p>报备商户</p>
          </td>
          <td>
            <p>报备商户号</p>
          </td>
          <td>
            <p>第三方商户号</p>
          </td>
          <td>
            <p>激活状态</p>
          </td>
          <td>
            <p>店铺名称</p>
          </td>
          <td>
            <p>店铺编码</p>
          </td>
          <td style="width: 60px">
            <p>操作</p>
          </td>
        </tr>
        <tr v-for="(v, i) in tabData" :key="i">
          <td>
            <p>{{ v.tradeMchName }}</p>
          </td>
          <td>
            <p>{{ v.tradeMchNo }}</p>
          </td>
          <td>
            <p>{{ v.thirdMchNo }}</p>
          </td>
          <td>
            <p> {{ v.activateStatus==true ? "已开启" : "已关闭" }}</p>
            <!-- <p>
              <el-switch
                disabled
                v-model="v.activateStatus"
                active-color="#13ce66"
              >
              </el-switch>
            </p> -->
          </td>
          <td>
            <p>{{ v.storeName }}</p>
          </td>
          <td>
            <p>{{ v.storeId }}</p>
          </td>
          <td style="width: 60px">
            <p>
              <a
                v-auth="'ACCOUNT:STORE:FIND/DETAIL'"
                href="javascript:;"
                @click="detailTab(v)"
                class="lianjie"
                >详情</a
              >
            </p>
          </td>
        </tr>
      </table>
    </div>
    <el-pagination
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="query.pageNo"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="query.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="totalNum"
    >
    </el-pagination>
  </div>
</template>
<script>
import { getShopFindList } from "@/api/user/shopList.js";
import { mapState, mapMutations } from "vuex";
export default {
  data() {
    return {
      query: {
        pageNo: 1,
        pageSize: 10,
        tradeMchNo: "", //报备商户号
        productChannelMchNo: "", //第三方商户号
      },
      totalNum: 0,
      tabData: [],
    };
  },
  computed: {
    ...mapState({
      storeFindQueryDetail: "user_storeFindQuery",
    }),
  },
  created() {
    if (this.storeFindQueryDetail) {
      this.query = this.storeFindQueryDetail.query;
    }
    this.getLists(false);
    this.$enter(this.$route.path, this.getLists);
  },
  methods: {
    ...mapMutations({
      setStoreFindQuery: "user_setStoreFindQuery",
    }),

    // 获取详情
    getLists(reset = true) {
      if (reset) {
        this.query.pageNo = 1;
      }
      this.query.tradeMchNo = this.query.tradeMchNo.trim();
      this.query.productChannelMchNo = this.query.productChannelMchNo.trim();

      getShopFindList(this.query).then((res) => {
        if (res.resultStatus) {
          res.resultData.tradeMchNoStore.forEach((h) => {
            if (h.activateStatus == 1) {
              h.activateStatus = true;
            } else {
              h.activateStatus = false;
            }
          });
          this.tabData = res.resultData.tradeMchNoStore;
          console.log("店铺查询表格数据", this.tabData);

          if (this.tabData.length == 0) {
            this.$message({
              message: "暂无数据",
              duration: 1500,
            });
          }
          this.totalNum = res.resultData.totalNum;
        }
      });
    },

    // 跳转详情页
    detailTab(value) {
      // console.log("InfoTypeInfoType", value);
      // return;
      this.$router.push({
        name: "shoplist_xq",
        query: {
          id: value.storeId,
          pageType: "edit",
          InfoType: value.storeSource,
        },
      });
    },
    // 页面大小改变
    handleSizeChange(size) {
      this.query.pageSize = size;
      this.getLists();
    },
    handleCurrentChange(page) {
      this.query.pageNo = page;
      this.getLists(false);
    },
  },
  beforeDestroy() {
    let data = {
      query: this.query,
    };
    this.setStoreFindQuery(data);
  },
};
</script>
<style scoped>
.con_from .lineP .item i {
  width: 92px;
}
.con_from .lineP .item .el-input , .con_from .lineP .item .el-select{
  width: calc(100% - 92px - 10px);
}
.con_from .lineP .itemTime .el-input{
  width: calc((100% - 92px - 24.67px - 10px) / 2);
}
</style>
