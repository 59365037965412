import axios from '@/utils/request'

//  店铺管理-店铺列表
export function storesList(params) {
  // params.loading = true
  return axios({
    url: `/manager/account/stores`,
    method: 'get',
		params
  })
}
//  店铺管理-更新轮询状态
export function setPollingStatus(data) {
  return axios({
    url: `/manager/account/stores/${data.storeId}/polling`,
    method: 'put',
		data
  })
}
//  店铺管理-更新展示状态
export function setShowStatus(data) {
  return axios({
    url: `/manager/account/stores/${data.storeId}/show`,
    method: 'put',
		data
  })
}
//  店铺管理-新增店铺(手工)
export function addManual(data) {
  return axios({
    url: `/manager/account/stores/add/manual`,
    method: 'post',
		data
  })
}
//  店铺管理-店铺详情(手工)
export function getManualDetail(storeId) {
  return axios({
    url: `/manager/account/stores/${storeId}/manual`,
    method: 'get'
  })
}
//  店铺管理-修改店铺详情(手工)
export function setManual(storeId, data) {
  return axios({
    url: `/manager/account/stores/${storeId}/manual`,
    method: 'put',
		data
  })
}
//  店铺管理-编辑交易商户号(手工)
export function setTrade(storeId, data) {
  return axios({
    url: `/manager/account/stores/${storeId}/trade`,
    method: 'post',
		data
  })
}
//  店铺管理-删除交易商户号(手工)
export function delTrade(storeId, tradeMchNo) {
  return axios({
    url: `/manager/account/stores/${storeId}/trade/${tradeMchNo}`,
    method: 'delete'
  })
}
//  店铺管理-新增店铺(API)
export function addApi(data) {
  return axios({
    url: `/manager/account/stores/add/api`,
    method: 'post',
		data
  })
}
//  店铺管理-店铺详情(API)
export function getApi(storeId) {
  return axios({
    url: `/manager/account/stores/${storeId}/api`,
    method: 'get'
  })
}
//  店铺管理-编辑店铺(API)
export function setApi(storeId, data) {
  return axios({
    url: `/manager/account/stores/${storeId}/api`,
    method: 'put',
		data
  })
}
//  店铺管理-进件店铺(API)
export function setApply(storeId) {
  return axios({
    url: `/manager/account/stores/${storeId}/apply`,
    method: 'post'
  })
}
//  店铺管理-编辑结算账户
export function setAccount(storeId, data) {
  return axios({
    url: `/manager/account/stores/${storeId}/account`,
    method: 'put',
		data
  })
}
//  店铺管理-编辑店铺配置
export function setConfig(storeId, data) {
  return axios({
    url: `/manager/account/stores/${storeId}/config`,
    method: 'put',
		data
  })
}
//店铺管理-交易商户号列表
export function getTrademchnos(storeId, params) {
  return axios({
    url: `/manager/account/stores/${storeId}/trademchnos`,
    method: 'get',
		params
  })
}
//店铺管理-同步交易商户信息
export function setTradeSync(storeId, tradeMchNo) {
  return axios({
    url: `/manager/account/stores/${storeId}/trade/${tradeMchNo}/sync`,
    method: 'post'
  })
}
//店铺管理-交易商户审核信息
export function getTradeAudit(storeId, tradeMchNo) {
  return axios({
    url: `/manager/account/stores/${storeId}/trade/${tradeMchNo}/audit`,
    method: 'get'
  })
}
//店铺管理-店铺特约配置
export function getTradeWx(storeId, tradeMchNo) {
  return axios({
    url: `/manager/account/stores/${storeId}/trade/${tradeMchNo}/wx`,
    method: 'get'
  })
}
//店铺管理-编辑店铺特约配置
export function setTradeWx(storeId, tradeMchNo, data) {
  return axios({
    url: `/manager/account/stores/${storeId}/trade/${tradeMchNo}/wx`,
    method: 'post',
		data
  })
}
//店铺管理-更新交易商户激活状态
export function setTradeActivate(storeId, data) {
  return axios({
    url: `/manager/account/stores/${storeId}/trade/${data.tradeMchNo}/activate`,
    method: 'put',
		data
  })
}
//店铺管理-交易商户产品报备
export function getTradeConfig(storeId, tradeMchNo) {
  return axios({
    url: `/manager/account/stores/${storeId}/trade/${tradeMchNo}/config`,
    method: 'get'
  })
}
//店铺管理-编辑店铺特约配置
export function setTradeConfig(storeId, tradeMchNo, data) {
  return axios({
    url: `/manager/account/stores/${storeId}/trade/${tradeMchNo}/config`,
    method: 'put',
		data
  })
}
//店铺管理-进件队列
export function getQueues(params) {
  // params.loading = true
  return axios({
    url: `/manager/account/stores/queues`,
    method: 'get',
		params
  })
}
//店铺管理-查询交易商户信息
export function getStoresTrade(storeId, tradeMchNo,productCode) {
  return axios({
    url: `/manager/account/stores/${storeId}/trade/${tradeMchNo}`,
    method: 'get',
    params:{
      productCode
    }
  })
}
//店铺管理-编辑交易商户信息
export function setStoresTrade(tradeMchNo,data) {
  return axios({
    url: `/manager/account/stores/${data.storeId}/trade/${tradeMchNo}`,
    method: 'put',
		data
  })
}
// 设置备注
export function setReamrk(data){
  return axios({
    url: `/manager/account/stores/${data.storeId}/trade/${data.tradeMchNo}/remark`,
    method: 'put',
		data
  })
}
//店铺管理-店铺查询
export function getShopFindList(params) {
  return axios({
    url: `/manager/account/stores/tradeMchNo`,
    method: 'get',
		params
  })
}

//店铺列表-详情-新增受益人
export function newBeneficiary(data){
  return axios({
    url: `/manager/account/stores/add/storeBene`,
    method: 'post',
		data
  })
}